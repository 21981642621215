<template>
  <div>
    <div class="chungdahm-top portfolio-top">
      <v-row no-gutters class="portfolio-info-common">
        <v-col cols="8" sm="9" md="10">
          <span>청담러닝4.0 러닝포털 개발 / Client: Chungdahm Learning / Device : Web, Tab, Mobile phone / Date : 2018.9 ~ 2019.2 </span>
        </v-col>
        <v-col cols="4" sm="3" md="2" class="text-right">
          <v-btn icon color="white" @click="$router.go(-1)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <em></em>
      <p>Learning Portal</p>
    </div>
    <div class="chungdahm-overview-section">
      <h2>OVERVIEW</h2>
      <p>(주)청담러닝 온라인 교육 플랫폼의 혁신적인 변화를 선도하다! 청담 Learning Portal 4.0 Development & Publishing
        <br />
        웹/태블릿/모바일에서 사용 가능하도록 반응형 웹으로 제작되어 사용자의 편의성을 더욱 향상시킴
      </p>
    </div>
    <div class="chungdahm-overview-img">
      <em></em>
    </div>
    <div class="gray-box"></div>
    <div class="chungdahm-main-section">
      <h3>main</h3>
      <em></em>
    </div>
    <div class="chungdahm-sub-page">
      <em></em>
    </div>
    <div class="chungdahm-dashboard">
      <em></em>
    </div>
    <div class="chungdahm-mobile-section">
      <h3>Mobile</h3>
      <em></em>

    </div>
    <div class="chungdahm-responsive-section">
      <h3>Responsive Web</h3>
      <em></em>
    </div>

    <div class="chungdahm-emoji-store-section">
      <h3> EMOJI STORE</h3>
      <em></em>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChungdahmLearning',
}
</script>
