<template>
  <div>
    <div class="officecore-web-top portfolio-top">
      <v-row no-gutters class="portfolio-info-common">
        <v-col cols="8" sm="9" md="10">
          <span>Officecore 웹사이트 디자인 & 퍼블리싱 / Client: Itcrew / Device : Web / Date : 2018.05~2018.07 </span>
        </v-col>
        <v-col cols="4" sm="3" md="2" class="text-right">
          <v-btn icon color="white" @click="$router.go(-1)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <em></em>
      <p>그룹웨어 전문회사 오피스코어 웹사이트 디자인 & 퍼블리싱</p>
    </div>
    <div class="officecore-web-concept-section">
      <h2>CONCEPT</h2>
      <p>그룹웨어 웹사이트의 성격상 솔루션에 대한 홍보, 마케팅을 위한 공간이라는 생각으로 노출 콘텐츠를 최소화하여 깔끔한 구성을 적용<br />
        사용자의 이해를 돕고 신뢰성 있고 안정적인 느낌으로 서비스를 이용하고 싶은 욕구를 자극하는 디자인으로의 개선이 필요함</p>
      <em></em>
    </div>
    <div class="officecore-web-gray-bg">

    </div>
    <div class="officecore-web-main">
      <em></em>
    </div>
    <div class="officecore-web-color-text">
      <span>
        <h2 class="sub-tit">COLOR & TEXT</h2>
        <p class="sub-p">기존 아이텐티티의 탁한 청록 색상을 네이비 컬러로 변경하여 전문적이고 안정적인 느낌으로 보이도록 하였고 조금 밝은 톤을 sub 컬러로 사용. 다소 어둡고 무거운 느낌을 중화시켰습니다.<br />
          서체는 간결하고 가독성 높은 웹폰트 Noto Sans KR을 적용하였습니다.
        </p>
      </span>
      <em></em>
    </div>
    <div class="officecore-web-responsive">
      <span>
        <h2 class="sub-tit">RESPONSIVE WEB</h2>
        <p class="sub-p">다양한 기기에 최적화 된 ‘반응형 웹’ 구축
        </p>
      </span>
      <em></em>
    </div>
    <div class="officecore-web-detail">
      <em></em>
    </div>
    <div class="officecore-web-detail-bottom">
      <span>
        <h2 class="sub-tit">DETAIL</h2>
        <p class="sub-p">컨텐츠가 많아서 복잡해 보일 수 있는 부분을 도식화된 그래픽을 사용하여
          사용자의 이해를 도울수 있도록 디자인
        </p>
      </span>
      <div class="text-center">
        <em class="detail-scroll-gif"></em>
        <em class="detail-sub-mockup"></em>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'OfficeCore',
}
</script>
  